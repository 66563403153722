export const allSymbols = [
    'dax',
    'dia',
    'eurz',
    'emfm',
    'usdy',
    'aapl',
    'goog',
    'orcl',
    'fb',
    'aaau',
    'rds.a',
];

export const SymbolsInStocks = {
    ETFs: ['dax', 'dia', 'eurz', 'emfm', 'usdy'],
    Stocks: ['aapl', 'goog', 'orcl', 'fb'],
    Other: ['aaau', 'rds.a'],
};
