import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TestCharts from "./charts/test-charts/test-charts";
import DiscoverLayout from "./layouts/discover-layout/discover-layout";
import SingleChartPage from './charts/singleChartPage';
import Learning from "./layouts/learning";
import WebinarDetails from "./layouts/webinarDetails";
import BookDetails from "./layouts/bookDetails";

export const App = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={DiscoverLayout} />
      <Route exact path="/learn/webinars/:id" component={WebinarDetails} />
      <Route exact path="/learn/books/:id" component={BookDetails} />
      <Route path="/learn/" component={Learning} />
      <Route exact path="/:symbol" component={SingleChartPage}/>
      <Route path="/charts/test" component={TestCharts} />
    </Switch>
  </Router>
);
