import React, { Fragment } from 'react';
import {
    XYPlot,
    LineSeries,
} from 'react-vis';
import { useHistory } from 'react-router-dom';

const StockChart = ({ title, symbol, price, diff, data, logo }) => {
    let filteredData = data.map((el, index) => {
        return { x: index, y: el.close };
    });

    const history = useHistory();

    const onClickToSingleChart = () => {
        history.push(`/${symbol.toLowerCase()}`);
        console.log('asdasd')
    };

    const monthDiff =
        filteredData[filteredData.length - 1].y - filteredData[0].y;

    return (
        <div
            className='inline-block no-wrap h-40 w-40 mr-3 rounded-lg shadow-xl rotate-90 origin-top-right bg-white cursor-pointer'
            onClick={onClickToSingleChart}
        >
            <div className='flex w-full h-full flex-col'>
                <div className='flex flex-col w-full mb-1'>
                    <div className='flex w-full justify-center px-4 py-2'>
                        <div className='flex w-full font-bold text-auto text-xs justify-center'>
                            <p className='flex truncate'>{title}</p>
                        </div>
                    </div>
                    <div className='flex justify-center'>
                        <div className='font-base text-xs text-gray-400 uppercase mr-3'>
                            {symbol}
                        </div>
                    </div>
                </div>
                <div className='flex flex-row w-full items-end font-medium text-xs pr-3 justify-between'>
                    <div className='flex h-full w-auto ml-4'>
                        <div className='flex items-center w-6 h-auto'>
                            {logo ? <img src={logo} alt='logo' /> : null}
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <div className='flex justify-end'>{`${price} $`}</div>
                        <div
                            className={
                                monthDiff >= 0
                                    ? 'flex justify-end text-green-400'
                                    : 'flex justify-end text-red-400'
                            }
                        >
                            &nbsp;
                            {monthDiff > 0
                                ? `+${
                                      parseInt(
                                          (filteredData[filteredData.length - 1]
                                              .y /
                                              monthDiff) *
                                              100
                                      ) / 100
                                  } %`
                                : `${
                                      parseInt(
                                          (filteredData[0].y / monthDiff) * 100
                                      ) / 100
                                  } %`}
                        </div>
                    </div>
                </div>
                <div className='relative flex flex-col w-full'>
                    <div className='flex justify-start text-xs uppercase text-gray-400 pl-2 mb-2'>
                        past 1 month
                    </div>
                    <div className='flex'>
                        <XYPlot height={70} width={150}>
                            <LineSeries
                                data={filteredData}
                                color={monthDiff >= 0 ? 'green' : 'red'}
                            />
                        </XYPlot>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StockChart;
