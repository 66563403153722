import React from "react";

const Install = ({ prompt }) => {
  if (!prompt) {
    return false;
  }

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }
    return Promise.reject(
      new Error(
        'Tried installing before browser sent "beforeinstallprompt" event'
      )
    );
  };

  return (
    <div className="absolute top-0 right-0 p-6">
      <button onClick={promptToInstall} className="bg-blue-800 py-2 px-4 rounded-full text-white font-semibold text-base">
        Install
      </button>
    </div>
  );
};

export default Install;
