import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../../../node_modules/react-vis/dist/styles/examples.scss';
import {
    XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    LineSeries,
    Crosshair,
} from 'react-vis';

const TestCharts = () => {
    // const DATA = [
    //     { x: 0, y: 8 },
    //     { x: 1, y: 5 },
    //     { x: 2, y: 4 },
    //     { x: 3, y: 9 },
    //     { x: 4, y: 1 },
    //     { x: 5, y: 7 },
    //     { x: 6, y: 6 },
    //     { x: 7, y: 3 },
    //     { x: 8, y: 2 },
    //     { x: 9, y: 0 },
    //     { x: 10, y: 8 },
    //     { x: 11, y: 5 },
    //     { x: 12, y: 4 },
    //     { x: 13, y: 9 },
    //     { x: 14, y: 1 },
    //     { x: 15, y: 7 },
    //     { x: 16, y: 6 },
    //     { x: 17, y: 3 },
    //     { x: 18, y: 2 },
    //     { x: 19, y: 0 },
    // ];

    useEffect(() => {
        (async () => {
            const token = 'pk_48c7c45d34c04c85af5d3a76e12747d1';
            const symbol = 'aapl';
            const url = `https://cloud.iexapis.com/stable/stock/${symbol}/chart?token=${token}`;
            try {
                const data = await axios.get(url);
                setData(data.data);
            } catch (error) {
                alert('API Error');
                console.error('API error ', error);
            }
        })();
    }, [])

    const [data, setData] = useState([]);

    let filteredData = data.map((el, index) => {
        return {x: index, y: el.close}
    })

    console.log(data);

    return (
        <div className="flex content-center justify-center mt-32">
            <XYPlot height={500} width={800}>
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis />
                <YAxis />
                <LineSeries data={filteredData}/>
            </XYPlot>
        </div>
    );
};

export default TestCharts;