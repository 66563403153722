import React from "react";

const ContentBlock = ({ title, children, horizontal = true }) => {
  return (
    <div className="flex flex-col">
      <div className="px-2">{title}</div>
      {horizontal ? (
        <div className="w-screen flex-row overflow-auto pt-2 pb-8 px-4 whitespace-no-wrap">
          {children}
        </div>
      ) : (
        <div className="w-screen flex-row overflow-auto pt-2 pb-8 pl-4 flex-wrap">
          {children}
        </div>
      )}
    </div>
  );
};

export default ContentBlock;