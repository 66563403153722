import React from "react";
import { Link } from "react-router-dom";
import noImage from "../../../../assets/img/noimage.png";

const WebinarItem = ({ webinar }) => (
  <Link to={`/learn/webinars/${webinar.id}`} key={`webinar${webinar.id}`}>
    <div className="inline-block no-wrap h-48 w-40 mr-3 rounded-lg shadow-xl rotate-90 origin-top-right bg-white">
      <div
        className="bg-gray-300 w-40 h-32 rounded-lg"
        style={{
          backgroundImage: `url(${noImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <img
          src={webinar.thumbnail}
          className="w-40 h-32 rounded-lg object-cover"
        />
      </div>
      <div className="flex flex-col p-2 font-medium overflow-hidden justify-start align-middle">
        <p className="text-sm truncate">{webinar.title}</p>
        <p className="text-xs font-light uppercase text-gray-600">{`- ${webinar.type}`}</p>
      </div>
    </div>
  </Link>
);

export default WebinarItem;
