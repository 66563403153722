import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const SearchBar = () => {
    const [data, setData] = useState([]);
    const history = useHistory();

    const onChangeHandler = (e) => {
        setData(e.target.value)
    };

    const onSubmitHandler = () => {
        history.push(`/${data}`);
        window.location.reload();
    };

    return (
        <div className='relative'>
            <form onSubmit={onSubmitHandler}>
                <input
                    onChange={onChangeHandler}
                    value={data}
                    className='shadow rounded-lg appearance-none w-full py-2 pl-3 pr-10 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                    id='search'
                    type='text'
                    placeholder='Search for symbols...'
                />
                <div className='absolute w-8 h-8 top-0 right-0 pointer-cursor'>
                    <button type='submit'><img className='w-6 h-6 pt-1' src={'https://img.icons8.com/all/500/search--v1.png'} /></button>
                </div>
            </form>
        </div>
    );
};

export default SearchBar;
