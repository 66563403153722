import React from 'react';

const News = ({ title, text, image, link }) => {
    
    return (
        <div className='flex flex-row w-full h-auto flex-auto justify-between mb-5'>
            <div className='w-16 h-16 bg-gray-400 rounded-lg'>
                <a href={link}>
                    <img className='w-full h-full rounded-lg object-cover' src={image} alt={'test'}/>
                </a>
            </div>
            <div className='flex flex-col w-3/4 h-auto font-sans'>
                <div className='text-sm font-bold'>{title}</div>
                {/* <div className='text-xs'>
                    {text}
                </div> */}
            </div>
        </div>
    );
};

export default News;
