import React from "react";
import { Link } from "react-router-dom";
import noImage from "../../../../assets/img/noimage.png";

const BookItem = ({ book }) => (
  <Link to={`/learn/books/${book.id}`} key={`book${book.id}`}>
    <div className="inline-block no-wrap h-48 w-40 mr-3 mb-3 rounded-lg shadow-xl rotate-90 origin-top-right bg-white">
      <div
        className="bg-gray-300 bg-local w-40 h-36 rounded-lg"
        style={{
          backgroundImage: `url(${noImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <img
          src={book.thumbnail}
          className="w-full object-cover h-36 rounded-lg"
        />
      </div>
      <div className="flex flex-col p-2 font-medium overflow-hidden justify-start align-middle">
        <p className="text-sm truncate">{book.title}</p>
        <div className="flex flex-row justify-between text-xs text-gray-600 font-light">
          <p>{book.type}</p>
          <p>{book.uploadDate}</p>
        </div>
      </div>
    </div>
  </Link>
);

export default BookItem;
