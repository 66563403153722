import React from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "../../navbar";
import books from "../../../assets/data/books.json";
import BookItem from "../learning/components/bookItem";
import ContentBlock from "../discover-layout/content-block";
import applePay from "../../../assets/img/apple_pay_button.png"

const getCurrent = (id) =>
  books.data.filter((item) => item.id === Number(id));
const getOther = (id) => books.data.filter((item) => item.id !== Number(id));

const BookDetails = () => {
  let { id } = useParams();
  const book = getCurrent(id)[0];
  const others = getOther(id);

  return (
    <div className="flex flex-col min-h-screen text-gray-800 bg-gray-100">
      <div className="flex-1">
        <div className="flex justify-between items-center p-4 pr-8 w-full">
          <Link to="/learn">
            <div className="font-semibold text-lg"></div>
            {/* <div className="font-semibold text-lg">&larr;</div> */}
          </Link>
          <div className="text-md h-4 font-semibold">Book</div>
          <p />
        </div>
        <div className="bg-gray-200">
          <img className="w-full h-64 object-none" src={book.thumbnail}/>
        </div>
        <div className="p-4">
          <div className="font-semibold text-xl">{book.title}</div>
          <div className="flex text-gray-800 text-lg mt-2">
            <div className="mr-2 font-light">{book.type}</div>
            <div className="mr-2 font-semibold">{book.price}</div>
          </div>
          <div className=" font-normal text-md mt-2">{book.description}</div>
          <p className="my-2 font-bold text-lg">Author</p>
          <div className="flex flex-row items-center mb-2">
            <img
              className="rounded-lg w-10 h-10 object-cover"
              src={book.userPortrait}
              alt="user"
            />
            <p className="ml-2 font-semibold text-sm">{book.userName}</p>
          </div>
          <div className="flex justify-center mt-6">
            <img src={applePay} alt=""/>
          </div>
        </div>
        <div className="mt-2 mb-12 text-lg font-semibold">
          <ContentBlock title="You may also be interested">
            {others.map((book, index) => {
              return (
                <BookItem key={index} book={book} />
              );
            })}
          </ContentBlock>
        </div>
      </div>
      <Navbar />
    </div>
  );
};

export default BookDetails;
