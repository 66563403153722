import React from "react";
import WebinarItem from "./components/webinarItem";
import BookItem from "./components/bookItem";
import ContentBlock from "../discover-layout/content-block";
import Navbar from "../../navbar";
import webinars from "../../../assets/data/webinars.json";
import books from "../../../assets/data/books.json";

const Learning = () => (
  <div className="flex flex-col min-h-screen relative">
    <div className="font-sans flex flex-1 flex-col w-full justify-start bg-gray-100 overflow-x-hidden overflow-y-scroll mb-10">
      <div className="flex flex-col px-4">
        <div className="mt-6 font-bold text-3xl">Learning</div>
        <div className="mb-8">
          Content &amp; learning resources around investment
        </div>
      </div>
      <div className="flex flex-col pl-4 font-sans text-xl font-semibold">
        <ContentBlock title="Webinars & Online Courses">
          {webinars.data.map((webinar, index) => (
            <WebinarItem key={index} webinar={webinar} />
          ))}
        </ContentBlock>
        <ContentBlock title="Books, E-books & Audio Books" horizontal={false}>
          {books.data.map((book, index) => (
            <BookItem key={index} book={book} />
          ))}
        </ContentBlock>
      </div>
      <Navbar />
    </div>
  </div>
);

export default Learning;
