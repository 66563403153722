import axios from 'axios';
import { allSymbols } from '../../charts/symbols';

export default async function () {
    const token = 'pk_5acc90c04d44421b9f68b0e208a4f79e';
    try {
        if(localStorage.length === 0) {
            for (let i of allSymbols) {
                if (!localStorage.getItem(i)) {
                    const data = await axios.get(
                        `https://cloud.iexapis.com/stable/stock/${i}/batch?types=quote,logo,chart&range=1m&token=${token}`
                    );
                    localStorage.setItem(i, JSON.stringify(data.data));
                }
            }
            window.location.reload();
        }
    } catch (error) {
        console.error('Error with api!')
    }
}
