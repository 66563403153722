import React, { useEffect, useState } from "react";
import ContentBlock from "./content-block";
import StockChart from "./stock-chart";
import Navbar from "../../navbar";

import getSymbols from "./stockRequests";

import { SymbolsInStocks } from "../../charts/symbols";
import SimpleBackdrop from "../../utils/backdrop";
import Install from "../../install";

const DiscoverLayout = () => {
  const [install, setInstall] = useState(false);
  const [prompt, setPrompt] = useState(null);

  useEffect(() => {
    (async () => {
      await getSymbols();
      if (localStorage.length !== 0) {
        setLoaded(true);
      }
    })();
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      console.log("Install Prompt fired", e);
      setPrompt(e)
      if((window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone === true){
        return false;
      }
      setInstall(true);
    });
  }, []);

  const [loaded, setLoaded] = useState(false);

  let render = loaded ? (
    <div className="flex flex-col min-h-screen relative">
      {install ? <Install prompt={prompt} /> : null}
      <div className="font-sans flex flex-1 flex-col w-full justify-start bg-gray-100 overflow-x-hidden overflow-y-scroll mb-10">
        <div className="flex flex-col px-4">
          <div className="mt-6 font-bold text-3xl">Discover</div>
          <div className="mb-8">
            Investments from a curated list of financial products such as ETFs,
            stocks and bonds.
          </div>
        </div>
        <div className="flex flex-col px-4 font-sans text-xl font-semibold pb-16">
          <ContentBlock title={"Award winning ETFs"}>
            {SymbolsInStocks.ETFs.map((stock) => {
              const curStock = JSON.parse(localStorage.getItem(stock));
              if (localStorage.getItem(stock)) {
                return (
                  <StockChart
                    key={curStock.quote.companyName}
                    title={curStock.quote.companyName}
                    symbol={curStock.quote.symbol}
                    price={curStock.quote.latestPrice}
                    diff={
                      curStock.chart[curStock.chart.length - 1].changePercent
                    }
                    data={curStock.chart}
                  />
                );
              }
            })}
          </ContentBlock>
          <ContentBlock title={"Alternative Investments"}>
            {SymbolsInStocks.Stocks.map((stock) => {
              const curStock = JSON.parse(localStorage.getItem(stock));
              if (localStorage.getItem(stock)) {
                return (
                  <StockChart
                    key={curStock.quote.companyName}
                    title={curStock.quote.companyName}
                    symbol={curStock.quote.symbol}
                    price={curStock.quote.latestPrice}
                    diff={
                      curStock.chart[curStock.chart.length - 1].changePercent
                    }
                    data={curStock.chart}
                    logo={curStock.logo.url}
                  />
                );
              }
            })}
          </ContentBlock>
          <ContentBlock title={"Others"}>
            {SymbolsInStocks.Other.map((stock) => {
              const curStock = JSON.parse(localStorage.getItem(stock));

              if (localStorage.getItem(stock)) {
                return (
                  <StockChart
                    key={curStock.quote.companyName}
                    title={curStock.quote.companyName}
                    symbol={curStock.quote.symbol}
                    price={curStock.quote.latestPrice}
                    diff={
                      curStock.chart[curStock.chart.length - 1].changePercent
                    }
                    data={curStock.chart}
                    logo={curStock.logo.url}
                  />
                );
              } else {
                return null;
              }
            })}
          </ContentBlock>
        </div>
      </div>
      <Navbar searchBar={true} />
    </div>
  ) : (
    <SimpleBackdrop isOpen={true} />
  );

  return render;
};

export default DiscoverLayout;
