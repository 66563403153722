import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {
    XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    LineSeries,
} from 'react-vis';

import News from './news';
import NavBar from '../navbar';
import SimpleBackdrop from '../utils/backdrop';

const SingleChartPage = () => {
    const historyArray = useHistory().location.pathname.split('/');
    const symbol = historyArray[historyArray.length - 1];
    const token = 'pk_5acc90c04d44421b9f68b0e208a4f79e';

    useEffect(() => {
        (async () => {
            const url = `https://cloud.iexapis.com/stable/stock/${symbol}/batch?types=quote,news,logo,chart&range=1m&token=${token}`;
            try {
                const data = await axios.get(url);
                let filteredData = data.data.chart.map((el, index) => {
                    return { x: index, y: el.close };
                });
                const latestChart = data.data.chart[data.data.chart.length - 1];
                const usedData = {
                    companyName: data.data.quote.companyName,
                    symbol: data.data.quote.symbol,
                    closePrice: latestChart.close,
                    openPrice: latestChart.open,
                    change: parseInt(latestChart.change * 100) / 100,
                    changePercent: parseInt(latestChart.changePercent * 100) / 100,
                };
                const news = data.data.news;
                const logo = data.data.logo;
                const newData = {
                    filteredData,
                    usedData,
                    news,
                    logo
                };
                setData(newData);
                setIsError(true);
            } catch (error) {
                console.error('API error ', error);
                setIsError(true);
            }
        })();
    }, []);

    const [data, setData] = useState(null);
    const [allNews, setAllNews] = useState(false);
    const [isError, setIsError] = useState(false);

    const [monthData, setMonthData] = useState([
        { timestamp: '5d', active: false },
        { timestamp: '1m', active: true },
        { timestamp: '3m', active: false },
        { timestamp: '6m', active: false },
    ]);

    let threeLatestNews = [];
    if (data !== null) {
        for (let i = 0; i < 3; i++) {
            threeLatestNews.push(data.news[i]);
        }
    }

    const newsHandler = () => {
        setAllNews(true);
    };

    const changeTimestampHandler = async (timestamp) => {
        let url = `https://cloud.iexapis.com/stable/stock/${symbol}/batch?types=chart&range=${timestamp}&token=${token}&chartSimplify=true`;
        if (timestamp === '1m' || timestamp === '5d') {
            url = `https://cloud.iexapis.com/stable/stock/${symbol}/batch?types=chart&range=${timestamp}&token=${token}`;
        }
        try {
            const requestData = await axios.get(url);
            const newFilteredData = requestData.data.chart.map((el, index) => {
                return { x: index, y: el.close };
            });
            let newData = { ...data };
            newData.filteredData = newFilteredData;
            const newMonthsData = [...monthData];
            newMonthsData.map((month) =>
                month.timestamp === timestamp
                    ? (month.active = true)
                    : (month.active = false)
            );
            setData(newData);
            setMonthData(newMonthsData);
        } catch (error) {
            console.error('API Error ', error);
        }
    };

    const render = isError ? (
        <Fragment>
            <div className='flex flex-col w-full justify-start'>
                {data === null ? (
                    <div className='w-full min-h-screen flex items-center justify-center'>
                        <h1 className='center text-center text-2xl text-purple-500 font-sans'>
                            There's no such symbol. Please try again!
                        </h1>
                    </div>
                ) : (
                    <Fragment>
                        <div className='flex flex-col items-center pb-32'>
                            <div className='flex w-full justify-center mb-2 text-xl font-sans pl-10 pr-8 pt-10 pb-4 bg-gray-200 font-sans text-2xl font-medium'>
                                {data.usedData.companyName}
                            </div>
                            <div className='flex flex-row w-full justify-between p-4 font-sans mb-12'>
                                <div className='flex flex-col'>
                                    <div className='flex flex-col w-40 break-words text-base font-sans font-semibold'>
                                        <span className='text-gray-400 uppercase'>
                                            symbol: {data.usedData.symbol}
                                        </span>
                                    </div>
                                    <div className='text-gray-400 uppercase font-semibold text-sm'>
                                        last price
                                    </div>
                                    <div className='text-3xl mt-1 text-blue-900'>
                                        {data.usedData.closePrice} $
                                    </div>
                                </div>
                                <div className='flex flex-col text-base flex items-center'>
                                    <div className='h-10 w-10'>
                                        <img src={data.logo.url} alt='logo' />
                                    </div>
                                    <div className='font-medium text-gray-400'>
                                        Day change
                                    </div>
                                    <div className='text-red-500 text-base font-medium'>
                                        {data.usedData.changePercent > 0 ? (
                                            <span className='text-green-400'>
                                                {`↑ ${data.usedData.change} (${data.usedData.changePercent} %)`}
                                            </span>
                                        ) : (
                                            <span className='text-red-400'>{`↓ ${data.usedData.change} (${data.usedData.changePercent} %)`}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <XYPlot height={350} width={350}>
                                <VerticalGridLines />
                                <HorizontalGridLines />
                                <YAxis />
                                <LineSeries
                                    data={data.filteredData}
                                    animation
                                />
                            </XYPlot>
                            <div className='flex flex-row justify-center mb-5'>
                                {monthData.map((month) => {
                                    if (!month.active) {
                                        return (
                                            <div
                                                key={month.timestamp}
                                                className='w-10 h-10 mr-4 border-2 border-solid border-gray-400 rounded-lg flex justify-center items-center font-sans text-gray-600 cursor-pointer'
                                                onClick={changeTimestampHandler.bind(
                                                    this,
                                                    month.timestamp
                                                )}
                                            >
                                                {month.timestamp}
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div
                                                key={month.timestamp}
                                                className='w-10 h-10 mr-4 border-2 border-solid border-gray-400 rounded-lg flex justify-center items-center font-sans bg-gray-300 text-white'
                                            >
                                                {month.timestamp}
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                            <div className='flex flex-col w-full px-6 mb-4'>
                                {data.news.length !== 0 ? (
                                    <div className='flex w-full justify-start font-sans font-medium text-2xl mb-3'>
                                        News
                                    </div>
                                ) : null}
                                <div className='flex flex-col w-full h-auto'>
                                    {!allNews && data.news.length !== 0
                                        ? threeLatestNews.map((el, index) => {
                                              let image =
                                                  'https://1.bp.blogspot.com/_GmDoFC87tnc/TB2tVkcjjRI/AAAAAAAAAo0/HFPQY0VFaB8/s1600/undefined_01.jpg';
                                              if (el.image !== undefined) {
                                                  image = el.image;
                                              }
                                              return (
                                                  <News
                                                      key={index}
                                                      image={image}
                                                      title={el.headline}
                                                      link={el.url}
                                                  />
                                              );
                                          })
                                        : null}
                                    {!allNews && data.news.length !== 0 ? (
                                        <div className='flex justify-center w-full h-auto'>
                                            <div
                                                className='flex w-auto font-semibold text-xl cursor text-blue-500'
                                                onClick={newsHandler}
                                            >
                                                More news...
                                            </div>
                                        </div>
                                    ) : null}
                                    {allNews && data.news.length !== 0
                                        ? data.news.map((el, index) => {
                                              let image =
                                                  'https://1.bp.blogspot.com/_GmDoFC87tnc/TB2tVkcjjRI/AAAAAAAAAo0/HFPQY0VFaB8/s1600/undefined_01.jpg';
                                              if (el.image !== undefined) {
                                                  image = el.image;
                                              }
                                              return (
                                                  <News
                                                      key={index}
                                                      image={image}
                                                      title={el.headline}
                                                      link={el.url}
                                                  />
                                              );
                                          })
                                        : null}
                                </div>
                            </div>
                            <div className='flex flex-row px-6 justify-around w-full mt-4 font-sans'>
                                <button className='px-12 py-3 bg-gray-200 rounded-lg text-base font-semibold text-green-700 cursor-pointer uppercase'>
                                    Buy
                                </button>
                                <button className='px-12 py-3 bg-gray-200 rounded-lg text-base font-semibold text-red-700 cursor-pointer uppercase'>
                                    Sell
                                </button>
                            </div>
                        </div>
                    </Fragment>
                )}
                <NavBar />
            </div>
        </Fragment>
    ) : (
        <SimpleBackdrop isOpen={true} />
    );

    return render;
};

export default SingleChartPage;
