import React from "react";
import { useParams, Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Navbar from "../../navbar";
import webinars from "../../../assets/data/webinars.json";
import ContentBlock from "../discover-layout/content-block";
import noImage from "../../../assets/img/noimage.png";
import WebinarItem from "../learning/components/webinarItem";

const getCurrent = (id) =>
  webinars.data.filter((item) => item.id === Number(id));
const getOther = (id) => webinars.data.filter((item) => item.id !== Number(id));

const WebinarDetails = () => {
  let { id } = useParams();
  const current = getCurrent(id)[0];
  const others = getOther(id);

  return (
    <div className="flex flex-col min-h-screen relative bg-gray-100 text-gray-800">
      <div className="flex-1">
        <div className="flex justify-between items-center p-4 pr-8 w-full absolute top-0">
          <Link to="/learn">
            <div className="font-semibold text-lg"></div>
            {/* <div className="font-semibold text-lg">&larr;</div> */}
          </Link>
          <div className="text-md h-4 font-semibold">Webinar</div>
          <p />
        </div>
        <ReactPlayer width="100%" url={current.video.videoUrl} />
        <div className="px-4">
          <div className="font-semibold text-xl">{current.title}</div>
          <div className="flex font-normal text-gray-600 text-md mb-2">
            <div className="mr-2">{current.uploadDate}</div> |
            <div className="ml-2">{current.video.time}</div>
          </div>
          <div className="flex flex-row items-center mb-2">
            <div className="bg-gray-300 w-10 h-10 rounded-lg">
              <img
                className="rounded-lg w-10 h-10 object-cover"
                src={current.userPortrait}
              />
            </div>
            <p className="ml-2 font-semibold text-sm">{current.userName}</p>
          </div>
        </div>
        <div className="px-4">{current.description}</div>
        <div className="mt-6 mb-10 text-lg font-semibold">
          <ContentBlock title="You may also be interested">
            {others.map((webinar) => {
              return <WebinarItem webinar={webinar} />;
            })}
          </ContentBlock>
        </div>
      </div>
      <Navbar />
    </div>
  );
};

export default WebinarDetails;
