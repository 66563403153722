import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import SearchBar from "./utils/search-bar";

const Navbar = ({ searchBar = false }) => {
  let location = useLocation();
  const discover = (location.pathname === '/') ? 'text-gray-900' : 'text-gray-600'
  const learn = (location.pathname === '/learn') ? 'text-gray-900' : 'text-gray-600'

  return (
    <div className="fixed bottom-0 w-full">
        {searchBar ? (
          <div className="mx-8 bg-gray-100">
            <SearchBar />
          </div>
        ) : null}
      <div className="flex flex-col justify-center bg-white pb-2 pt-2 w-full  shadow-inner shadow-md">
        <div className={searchBar ? "flex flex-row justify-around" : "flex flex-row justify-around"}>
          <Link to="/">
            <div className="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`w-full m-0 fill-current ${discover} `}
                width="35"
                height="35"
                viewBox="0 0 35 35"
              >
                <path
                  className="a"
                  d="M174.381,389.727H147.5V362.842a.967.967,0,1,0-1.934,0v28.819h28.819a.967.967,0,0,0,0-1.934Z"
                  transform="translate(-145.562 -361.875)"
                />
                <path
                  className="a"
                  d="M179.395,395.541a.967.967,0,0,0,.684.283c.03,0,.061,0,.091,0a.968.968,0,0,0,.709-.419l8.284-12.192,7.147,5.36a.967.967,0,0,0,1.161-1.547l-7.958-5.968a.967.967,0,0,0-1.38.23l-8.2,12.063L175.69,389.1a.967.967,0,0,0-1.368,1.368Z"
                  transform="translate(-168.071 -376.882)"
                />
              </svg>
              <p className={`text-xs ${discover}`}>Discover</p>
            </div>
          </Link>
          <Link to="/learn">
            <div className="flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`w-full m-0 fill-current ${learn} `}
                width="35"
                height="35"
                viewBox="0 0 35 35"
              >
                <path
                  className="a"
                  d="M316.867,216.008a20.822,20.822,0,0,0-9.131,1.827,20.452,20.452,0,0,0-4.823,3.069,20.452,20.452,0,0,0-4.823-3.069,20.818,20.818,0,0,0-9.131-1.827.967.967,0,0,0-.94.967v22.632a.967.967,0,0,0,.832.958,33.319,33.319,0,0,1,13.535,5.075c.01.007.021.011.031.017s.023.016.036.023l.033.015.048.022c.025.011.051.02.077.029l.047.015c.029.008.058.014.087.019l.041.007a.9.9,0,0,0,.258,0l.041-.007c.029-.005.058-.011.087-.019l.047-.015c.026-.008.052-.018.077-.029l.048-.022.033-.015c.013-.007.024-.016.036-.023s.021-.01.031-.017a33.32,33.32,0,0,1,13.535-5.075.967.967,0,0,0,.832-.958V216.975A.967.967,0,0,0,316.867,216.008Zm-26.914,22.769V217.939a18.834,18.834,0,0,1,7.338,1.657,18.543,18.543,0,0,1,4.656,3.029V243.1a35.239,35.239,0,0,0-11.993-4.321Zm25.921,0A35.24,35.24,0,0,0,303.88,243.1V222.625a18.544,18.544,0,0,1,4.655-3.029,18.827,18.827,0,0,1,7.338-1.657Z"
                  transform="translate(-288.018 -216)"
                />
              </svg>
              <p className={`text-xs ${learn}`}>Learn</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
